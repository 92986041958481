<template>
  <v-card flat tile :loading="loading">
    <v-toolbar dense flat>
      <TermTabs centered v-model="term" />
    </v-toolbar>
    <v-container>
      <v-card outlined class="mb-4" v-if="absences">
        <v-system-bar>Zusammenfassung</v-system-bar>
        <v-list>
          <RowItem title="pendent">
            {{ absences.pendingLessons }}
          </RowItem>
          <RowItem title="entschuldigt">
            {{ absences.excusedLessons }}
          </RowItem>
          <RowItem title="unentschuldigt">
            {{ absences.unexcusedLessons }}
          </RowItem>
          <RowItem title="bezogene Halbtage">
            {{ absences.halfDays }} <span class="grey--text">(ganzes SJ)</span>
          </RowItem>
        </v-list>
      </v-card>
      <v-card outlined class="mb-4" v-if="absences">
        <v-system-bar>Abwesenheiten</v-system-bar>

        <v-data-table
          dense
          :loading="loading"
          :headers="headers"
          :items="justifications"
          disable-pagination
          hide-default-footer
          :show-expand="principal"
          single-expand
          item-key="id"
          @click:row="(item) => showDetails(item.id)"
          :item-class="() => (hasRole('principal') ? 'clickable' : '')"
        >
          <template v-slot:item.startDate="{ item }">
            <DateValue :value="item.startDate" />
          </template>
          <template v-slot:item.endDate="{ item }">
            <DateValue :value="item.endDate" />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="3"></td>
            <td :colspan="headers.length - 3">{{ item.studentText }}</td>
          </template>
        </v-data-table>
      </v-card>
      <v-card outlined class="mb-4" v-if="absences">
        <v-system-bar>Heat Map</v-system-bar>
        <JustificationHeatmap :id="id" :term="term"></JustificationHeatmap>
      </v-card>
    </v-container>
    <router-view></router-view>
  </v-card>
</template>

<script>
import { saveChildRoute } from "@/router/helper";
import DateValue from "common/components/DateValue.vue";
import JustificationHeatmap from "common/components/Absences/JustificationHeatmap";
import RowItem from "@/components/RowItem";
import TermTabs from "@/components/TermTabs";

export default {
  name: "PersonAbsences",
  components: {
    DateValue,
    JustificationHeatmap,
    RowItem,
    TermTabs,
  },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
    showParents() {
      return this.parents && this.parents.length > 0;
    },
    principal() {
      return this.hasRole("principal");
    },
  },
  data() {
    return {
      absences: {},
      justifications: [],
      headers: [
        { text: "Art", value: "type.description" },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "Beschreibung", value: "description" },
        { value: "data-table-expand" },
        { text: "Status", value: "status.description" },
        { text: "Lektionen", value: "lessonCount", align: "right" },
      ],
      loading: false,
      term: null,
    };
  },
  methods: {
    async getData() {
      if (this.personId && this.term) {
        this.loading = true;
        this.absences = await this.apiGet({
          resource: "absence/overview",
          id: this.personId,
          query: `term=${this.term.id}`,
        });
        this.justifications = await this.apiList({
          resource: "absence/justification",
          query: `student=${this.personId}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
        });
        this.loading = false;
      }
    },
    showDetails(id) {
      if (!this.hasRole("principal")) {
        return;
      }
      this.$router.push({
        name: "PersonAbsencesJustification",
        params: { justificationId: id },
      });
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
    async term() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
